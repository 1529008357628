<template>
    <div class="addFeedback">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; padding: 20px">
            <el-form
                enctype="multipart/form-data"
                ref="form"
                :model="form"
                label-width="80px"
                :rules="rules"
                size="small"
            >
                <el-form-item label="反馈机构">
                    <el-select v-model="form.deptCode" filterable>
                        <el-option value="" label="请选择" />
                        <el-option
                            v-for="dept in meta.deptCodes"
                            :label="dept.name"
                            :key="dept.code"
                            :value="dept.code"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="需求分类">
                    <el-select
                        filterable
                        v-model="form.menuCode"
                        @change="handleChangeGroup"
                        :loading="loadingDeptGroupFlag"
                    >
                        <el-option value="" label="请选择" />
                        <el-option
                            v-for="group in meta.groups"
                            :value="group.code"
                            :key="group.code"
                            :label="group.name"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="需求名称" prop="name">
                    <el-input
                        style="width: 30%"
                        type="text"
                        placeholder="需求名称"
                        v-model="form.name"
                        show-word-limit
                        maxlength="30"
                    />
                </el-form-item>
                <el-form-item label="需求场景" prop="scene">
                    <el-input
                        type="textarea"
                        placeholder="需求场景"
                        v-model="form.scene"
                        show-word-limit
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        maxlength="500"
                    />
                </el-form-item>
                <el-form-item label="需求描述" prop="descInfo">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="需求场景"
                        v-model="form.descInfo"
                        show-word-limit
                        maxlength="500"
                    />
                </el-form-item>
                <el-form-item label="附件">
                    <feed-upload
                        :file-type="imgFileType"
                        v-model="uploadFiles"
                        :limit="5"
                        @change="changeUploadFiles"
                    />
                    <!--                    <el-upload-->
                    <!--                        class="upload-demo"-->
                    <!--                        action="#"-->
                    <!--                        :http-request="importFile"-->
                    <!--                        :on-preview="handlePreview"-->
                    <!--                        :on-remove="handleRemove"-->
                    <!--                        :before-remove="beforeRemove"-->
                    <!--                        multiple-->
                    <!--                        :limit="5"-->
                    <!--                        :on-exceed="handleExceed"-->
                    <!--                        :on-change="handleChange"-->
                    <!--                        :file-list="fileList"-->
                    <!--                    >-->
                    <!--                        <el-button size="small" type="primary">点击上传</el-button>-->
                    <!--                    </el-upload>-->
                    <!--                    <el-input v-model="form.file" style="display: none" />-->

                    <div style="margin-top: 10px; color: #bcbdc4">
                        <div>1.最多上传5个文件</div>
                        <div>2.文件上传限制大小为20M</div>
                        <div>3.支持扩展名：.rar .zip .doc .docx .pdf .jpg .jpeg .png .gif .bmp .xls .xlsx</div>
                    </div>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="handleSave" size="small">提交</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import { Message } from 'element-ui';

import FeedUpload from './feedBackUpload.vue';
import FeedBackUtils, { FeedBackFileUploadUrl } from '../../../../js/FeedBackUtils';
export default {
    components: { FeedUpload },
    name: 'FeedBackUpload',
    data() {
        return {
            uploadFiles: [],
            uploadAccessory: [],
            imgFileType: FeedBackUtils.FileTypeEnum.FILE_FEED,
            fileList: [],
            isContinue: false,
            deptGroupCode: '',
            form: {
                deptCode: '',
                menuCode: '',
                path: [],
                name: '',
                scene: '',
                descInfo: '',
            },
            meta: {
                groups: [],
                deptCodes: [],
                cards: [],
            },
            loadingDeptGroupFlag: true,
            url: {
                queryDeptByGroup: '/system/dept/deptTree',
                save: '/userFeedBack/createExtend',
            },
            rules: {
                name: [{ required: true, min: 1, max: 30, message: '需求名称必填,1到30个字', trigger: 'blur' }],
                scene: [
                    { required: true, min: 1, max: 500, message: '需求场景必填,最多可输入500个字符', trigger: 'blur' },
                ],
                descInfo: [
                    { required: true, min: 1, max: 500, message: '需求描述必填,最多可输入500个字符', trigger: 'blur' },
                ],
            },
        };
    },
    'form.uploadFiles'(a, b) {
        if (a.length == 5) {
            this.isContinue = true;
        } else {
            this.isContinue = false;
        }
    },
    created() {
        this.handleChangeGroup();
        UrlUtils.QueryRemote(this, '/authedMenus', (res) => {
            this.meta.groups = res;
            this.loadingDeptGroupFlag = false;
        });
    },
    methods: {
        changeUploadFiles(uploadFileArr, uploadFileUrlArr) {
            this.form.path = [];
            this.uploadFiles = uploadFileArr;
            //拼接url地址，实际上由于只会上传一个文件，对应的只生成一个url地址，所以拼接无影响
            this.uploadAccessory = uploadFileUrlArr.map((e = new FeedBackFileUploadUrl()) => {
                return e.advertFileUrl;
            });
            this.form.path = this.uploadAccessory;
        },
        handleChange(file, fileList) {
            // this.form.file = file;
        },

        importFile(file) {
            const formDatas = new FormData();
            formDatas.append('file', file.file.file);
            formDatas.append('name', file.file.name); //这"file" 代表参数名
        },
        handleRemove(file, fileList) {},
        handlePreview(file) {},
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择5个文件，本次选择了 ${files.length} 个文件，共选择了 ${
                    files.length + fileList.length
                } 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
        },
        handleChangeGroup() {
            const _this = this;
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + '', (rst) => {
                _this.meta.deptCodes = rst;
            });
        },
        //提交
        handleSave() {
            this.$refs.form.validate((valid) => {
                if (!valid) return;
                this.$http.post(this.url.save, this.form).then((rst) => {
                    if (rst.data.status === 200) {
                        Message.success({ message: '保存成功' });
                        this.form.menuCode = '';
                        this.form.path = '';
                        this.form.name = '';
                        this.form.scene = '';
                        this.form.descInfo = '';
                        this.form.deptCode = '';
                        this.goBackAndReload();
                    }
                });
            });
        },
    },
};
</script>
