import SparkMD5 from 'spark-md5';
import { get, post } from 'request/http';
import { Message } from 'element-ui';

export default {
    FileTypeEnum: {
        IMG: 0,
        VIDEO: 1,
        FILE_FEED: 3,
        properties: {
            0: {
                desc: '图片',
                fileExtension: '.png,.gif,.jpg,.jpeg',
                fileType: new Set(['image/png', 'image/gif', 'image/jpg', 'image/jpeg']),
                fileSize: 3,
            },
            1: {
                desc: '视频',
                fileExtension: '.mpg,.mp4,.avi',
                fileType: new Set(['video/mpg', 'video/mp4', 'video/avi']),
                fileSize: 7,
            },
            3: {
                desc: '文件',
                fileExtension: '.png,.gif,.jpg,.jpeg,.rar,.zip,.doc,.docx,.xls,.xlsx',
                fileType: new Set([
                    'image/png',
                    'image/gif',
                    'image/jpg',
                    'image/jpeg',
                    '',
                    'application/msword',
                    'application/x-zip-compressed',
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                ]),
                fileSize: 20,
            },
        },
    },
    acceptFileType(fileType) {
        return this.FileTypeEnum.properties[fileType]?.fileExtension;
    },
    beforeUpload(file, fileType) {
        //-------------判断文件是否合规-----------------------
        const fileTypeEnum = this.FileTypeEnum.properties[fileType];
        if (!fileTypeEnum.fileType.has(file.type)) {
            //文件类型不匹配
            Message.error(`仅支持${fileTypeEnum.fileExtension}格式的图片`);
            return false;
        }
        if (file.size / 1024 / 1024 > fileTypeEnum.fileSize) {
            //文件过大
            Message.error(`文件大小不超过${fileTypeEnum.fileSize}MB`);
            return false;
        }
    },

    async uploadFile(param) {
        const file = param.file;
        const fileMD5 = await new Promise(function (resolve, reject) {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(SparkMD5.hashBinary(e.target.result));
            };
            reader.readAsBinaryString(file);
        });
        //--------------------从阿里云oss获取凭证----------------------------
        const objectPolicy = await get('/oss/createPostObjectPolicy');
        const formData = new FormData();
        const key =
            objectPolicy.dir + 'feedback/' + fileMD5 + '.' + file.name.substring(file.name.lastIndexOf('.') + 1);
        const advertFileUrl = objectPolicy.host + key;
        formData.set('key', key);
        formData.set('policy', objectPolicy.policy);
        formData.set('signature', objectPolicy.signature);
        formData.set('OSSAccessKeyId', objectPolicy.accessId);
        formData.set('name', objectPolicy.host + key);
        formData.set('file', file);
        //200：上传成功后的response状态
        formData.set('success_action_status', 200);
        //-----------------------将数据和文件上传到阿里云oss-------------------
        let requestUrl = objectPolicy.host;
        //根据host起始是否有http动态增加https前缀
        if (!requestUrl.startsWith('http')) {
            if (requestUrl.startsWith('//')) {
                requestUrl = 'https:' + requestUrl;
            } else {
                requestUrl = 'https://' + requestUrl;
            }
        }
        await post(requestUrl, null, formData, {
            withCredentials: false,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        //返回广告地址
        return { advertFileUrl };
    },
    async uploadFileFeedBack(param) {
        const file = param.file;
        const fileMD5 = await new Promise(function (resolve, reject) {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(SparkMD5.hashBinary(e.target.result));
            };
            reader.readAsBinaryString(file);
        });
        const formData = new FormData();
        const key = fileMD5 + '.' + file.name.substring(file.name.lastIndexOf('.') + 1);
        const advertFileUrl = key;
        formData.set('key', key);
        formData.set('name', key);
        formData.set('file', file);
        //200：上传成功后的response状态
        formData.set('success_action_status', 200);
        await post('/system/backConfig/helpCenter/uploadDescDoc', null, formData, {
            withCredentials: false,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        //返回广告地址
        return { advertFileUrl };
    },

    isImgAdvertFileType(advertFileType) {
        return advertFileType === this.FileTypeEnum.IMG;
    },
    isVideoAdvertFileType(advertFileType) {
        return advertFileType === this.FileTypeEnum.VIDEO;
    },
};

export class FeedBackFileUploadUrl {
    constructor(advertFileUrl) {
        this.advertFileUrl = advertFileUrl;
    }
}
